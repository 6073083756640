<template>
  <div class="home text-center">
    <div class="home-hero">
      <img src="/images/gallrom-text.png" class="home-logo" />
      <router-link :to="{ name: 'GallRomDetails' }" class="home-details">
        Documents et analyses de la Galloromania médiévale
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="30"
          width="30"
          viewBox="0 0 512 512"
        >
          <path
            d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z"
          />
        </svg>
      </router-link>
    </div>

    <div class="grid-container">
      <div class="grid-item">
        <div class="tile-box">
          <div
            class="tile-image"
            style="background-image: url(/images/1_GallRomBibl.jpg)"
          ></div>
          <div class="tile-text">
            <h3>GallRomBibl</h3>
            <p>Répertoires bibliographiques de textes galloromans médiévaux</p>
          </div>
          <div class="tile-over-text" @click="openDetails('GallRomBibl')">
            <h3>GallRomBibl</h3>
            <b
              ><i
                >Répertoires bibliographiques de textes galloromans médiévaux</i
              ></b
            ><br />
            Version électronique<br />
            dirigée par Martin Glessgen<br />
            en partenariat avec Caterina Menichetti et Frankwalt Möhren<br />
            Version préparatoire (2024)
            <div class="button-details">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="30"
                width="30"
                viewBox="0 0 512 512"
              >
                <path
                  d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-item">
        <div class="tile-box">
          <div
            class="tile-image"
            style="background-image: url(/images/2_DocLing.jpg)"
          ></div>
          <div class="tile-text">
            <h3>DocLing</h3>
            <p>Documents linguistiques galloromans</p>
          </div>
          <div class="tile-over-text" @click="openDetails('DocLing')">
            <h3>DocLing</h3>
            <b><i>Documents linguistiques galloromans</i></b
            ><br />
            Édition électronique<br />
            dirigée par Martin Glessgen<br />
            en partenariat avec Frédéric Duval, Paul Videsott et Hélène
            Carles<br />
            Quatrième édition (2024)
            <div class="button-details">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="30"
                width="30"
                viewBox="0 0 512 512"
              >
                <path
                  d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-item">
        <div class="tile-box">
          <div class="tile-text">
            <h3>LEGaMe</h3>
            <p>Lexique étymologique de la Galloromania médiévale</p>
          </div>
          <div
            class="tile-image"
            style="background-image: url(/images/3_LEGaMe.jpg)"
          ></div>
          <div class="tile-over-text" @click="openDetails('LEGaMe')">
            <h3>LEGaMe</h3>
            <b><i>Lexique étymologique de la Galloromania médiévale</i></b
            ><br />
            Base de données lexicographique<br />
            dirigée par Martin Glessgen<br />
            en partenariat avec Hélène Carles, Marguerite Dallas, Cristina
            Dusio, Seraina Montigel et Thomas Städtler<br />
            Version préparatoire (2024)
            <div class="button-details">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="30"
                width="30"
                viewBox="0 0 512 512"
              >
                <path
                  d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-item">
        <div class="tile-box">
          <div class="tile-text">
            <h3>Scripta</h3>
            <p>Analyses scriptologiques des DocLing</p>
          </div>
          <div
            class="tile-image"
            style="background-image: url(/images/4_Scripta.jpg)"
          ></div>
          <div class="tile-over-text" @click="openDetails('Scripta')">
            <h3>Scripta</h3>
            <b><i>Analyses scriptologiques des DocLing</i></b
            ><br />
            dirigée par Martin Glessgen et Paul Videsott<br />
            en collaboration avec Cristina Dusio, Jessica Meierhofer et Marco
            Robecchi
            <div class="button-details">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="30"
                width="30"
                viewBox="0 0 512 512"
              >
                <path
                  d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--
    <table class="home-table">
      <tr>
        <td class="home-table-img">
          <div style="background-image: url(/tiles/1_GallRomBibl.jpg)"></div>
        </td>
        <td class="home-table-text">
          <h3>GallRomBibl</h3>
          <p>Répertoires bibliographiques de textes galloromans médiévaux</p>
          <!- - <p>[DEAFBibl, DEAOBibl/RepCrit, DEAGBibl, DocLingBibl]</p> - ->
        </td>
        <td class="home-table-img">
          <div style="background-image: url(/tiles/2_DocLing.jpg)"></div>
        </td>
        <td class="home-table-text">
          <h3>DocLing</h3>
          <p>Documents linguistiques galloromans</p>
        </td>
      </tr>
      <tr>
        <td class="home-table-text">
          <h3>LEGaMe</h3>
          <p>Lexique étymologique de la Galloromania médiévale</p>
          <!- - <p>
            [Trésor étymologique d'ancien français (TEAF)<br />Dictionnaire
            étymologique d'ancien Gascon (DEAG)]
          </p> - ->
        </td>
        <td class="home-table-img">
          <div style="background-image: url(/tiles/3_LEGaMe.jpg)"></div>
        </td>
        <td class="home-table-text">
          <h3>Scripta</h3>
          <p>Analyses scriptologiques des DocLing</p>
        </td>
        <td class="home-table-img">
          <div style="background-image: url(/tiles/4_Scripta.jpg)"></div>
        </td>
      </tr>
    </table>
    -->

    <!-- <div class="container-fluid mt-5">
      <div class="row d-flex">
        <div class="flex-grow-1">
          <img src="/tile1.webp" class="tile-image" />
        </div>
        <div class="flex-grow-1">
          <h3>GallRomBibl</h3>
          <p>Répertoires bibliographiques de textes galloromans médiévaux</p>
          <p>[DEAFBibl, DEAOBibl/RepCrit, DEAGBibl, DocLingBibl]</p>
        </div>
        <div class="flex-grow-1">
          <img src="/tile2.webp" class="tile-image" />
        </div>
        <div class="flex-grow-1">
          <h3>DocLing</h3>
          <p>Documents linguistiques galloromans</p>
        </div>
      </div>
      <div class="row d-flex">
        <div class="flex-grow-1">
          <h3>LEGaMe</h3>
          <p>Lexique étymologique de la Galloromania médiévale</p>
          <p>[Trésor étymologique d'ancien français (TEAF)<br>Dictionnaire étymologique d'ancien Gascon (DEAG)]</p>
        </div>
        <div class="flex-grow-1">
          <img src="/tile3.webp" class="tile-image" />
        </div>
        <div class="flex-grow-1">
          <h3>DocLing</h3>
          <p>Documents linguistiques galloromans</p>
        </div>
        <div class="flex-grow-1">
          <img src="/tile4.webp" class="tile-image" />
        </div>
      </div>
    </div> -->
    <!-- <span v-if="environment == 'production'">
      This is release {{ version }} and this is productive version of LEGaM application (with real data).
    </span>
    <span v-else>
      This is release <b>{{ version }}</b> and this is <b>{{ environmentText }}</b> version of LEGaM application.
    </span> -->
  </div>
</template>

<style>
html,
body,
.home,
#app {
  height: 100%;
  margin: 0;
}
</style>

<style scoped>
.home {
  margin-top: -58px;
}
.home-logo {
  width: 550px;
  margin-top: 120px;
}

.home-hero {
  background-image: url("/images/gallrom-bg-red.jpg");
  background-size: cover;
  background-position: center;
  height: 300px;
  color: #fff;
}

.header {
  height: 100px;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  height: calc(100% - 300px); /* Reduced by the header's height */
  gap: 0px;
}

.grid-item {
  /* background-color: #f0f0f0; */
  padding: 0px;
  text-align: center;
  /* border: 1px solid #ccc; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.tile-box {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.tile-box h3 {
  margin-bottom: 30px;
}

.tile-box .tile-over-text {
  background-color: #5e0000e1;
  /* display: none; */
  position: absolute;
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: center;
  padding: 50px;
  cursor: pointer;
  /* visibility: hidden; */
  opacity: 0;
  transition: all 0.3s;
}

.tile-box:hover .tile-over-text {
  opacity: 1;
}

.button-details {
  position: absolute;
  right: calc(50% - 15px);
  bottom: 20px;
}

.button-details svg {
  fill: #fff;
  transition: all 0.3s;
}
.button-details svg:hover {
  opacity: 0.8;
}

.home-details {
  color: #fff !important;
  margin-top: 25px;
  display: block;
  transition: all 0.3s;
  font-size: 20px;
}

.home-details a {
  color: #fff;
  text-decoration: none;
}

.home-details a:hover {
  text-decoration: none !important;
}


.home-details svg {
  opacity: 0.7;
  fill: #fff;
  transition: all 0.3s;
}

.home-details:hover svg {
  opacity: 1;
}

@media screen and (max-width: 1200px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
}

@media screen and (max-width: 800px) {
  .home-logo {
    width: 50%;
  }

  .home-hero {
    height: 20vh;
  }
}

div.tile-image {
  transition: all 0.3s;
  background-size: cover;
  background-position: center;
  min-height: 20vh;
  height: 100%;
  width: 50%;
  display: inline-block;
  background-position: top;
}

div.tile-text {
  display: inline-block;
  height: 100%;
  width: 50%;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: calc((100vh - 300px) / 7);
}

/*
.home-table {
  width: 100%;
  height: 40%;
}
.home-table tr td {
  width: 25%;
}
.home-table-text {
  padding-left: 30px;
  padding-right: 30px;
}
.tile-image {
  width: 20vw;
}
.home-table-img div:hover {
  opacity: 0.9;
  cursor: pointer;
}
/* .home-table-img div,
.home-table-img {
  transition: all 0.3s;
  background-size: cover;
  background-position: center;
  min-height: 40vh;
  height: 100%;
  width: 100%;
} */
</style>

<script>
import store from "@/store";
import router from "@/router";

export default {
  name: "Home",
  methods: {
    openDetails(type) {
      router.push({ name: type });
      // console.log("Open", type)
    },
  },
  computed: {
    environment() {
      return store.getters.appSettings.environment;
    },
    environmentText() {
      let retval = store.getters.appSettings.environment;
      switch (store.getters.appSettings.environment) {
        case "production":
          retval = "productive";
          break;
        case "develop":
          retval = "development";
          break;
      }
      return retval;
    },
    version() {
      return store.getters.appSettings.version;
    },
  },
};
</script>
